// ============================================================================
// Scrollreveal
// ============================================================================

ScrollReveal({
    reset: true,
    distance: '80px',
    duration: 1500,
    delay: 100,
});

ScrollReveal().reveal('#webdesign', { origin: 'left' });
ScrollReveal().reveal('#softwaredev', { origin: 'right' });
ScrollReveal().reveal('#cloudservices', { origin: 'left' });
ScrollReveal().reveal('#better', { origin: 'right', mobile: false });

// ============================================================================
// Typed JS
// ============================================================================

const typed = new Typed('.typed-text', {
    strings: ['Software', 'Services', 'Tools', 'Web Applications', 'Desktop Applications', 'Cloud Solutions'],
    typeSpeed: 100,
    backSpeed: 50,
    backDelay: 2000,
    loop: true,
});

// ============================================================================
// Google reCaptcha and analytics setup
// ============================================================================

grecaptcha.ready(function() {
    grecaptcha.execute('6LeD3dIlAAAAABBZsgmcrwRj5Eg_O5t_fMF8apef', {action: 'homepage'})
        .then(function(token) {
            document.getElementById('captchaResponse').value = token;
        });
});

window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', 'G-H640FZVKT1');

// ============================================================================
// Scroll background transitions
// ============================================================================

const bg1 = document.getElementById('bg1');
const bg2 = document.getElementById('bg2');
const bg3 = document.getElementById('bg3');
const bg4 = document.getElementById('bg4');
const sections = document.getElementsByTagName('section');
const backgrounds = [bg1, bg2, bg3, bg4];

window.onscroll = function() {
    const y = window.scrollY;
    for (let i = 0; i < sections.length; i++) {
        const top = sections[i].offsetTop;
        const height = sections[i].offsetHeight;
        const bottom = top + height;
        if (y > bottom) {
            backgrounds[i].style.opacity = '0';
        }
        else if(y >= top && y <= bottom) {
            let opacity = 1 - ((y - top) / (bottom - top));
            backgrounds[i].style.opacity = `${opacity}`;
            console.log(`BG ${i} opacity is ${opacity}`);
        }
        else {
            console.log(y, top, bottom)
            backgrounds[i].style.opacity = '1';
        }
    }
};